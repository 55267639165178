import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LayoutPublic.css";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";
import Footer from "../components/Footer/Footer";
import { Outlet } from "react-router-dom";
import LogoFooter from "../components/LogosFooter/LogoFooter";
import { HelmetProvider } from "react-helmet-async";
import { FloatButton } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useContraste } from "../contexts/ContrasteContext";

const LayoutPublic = () => {
  const { contrasteActivado, toggleContraste } = useContraste();

  return (
    <>
      <div className={contrasteActivado ? "alto-contraste" : ""}>
        <HelmetProvider>
          <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
            <ResponsiveAppBar></ResponsiveAppBar>
          </div>
          <main className="container-fluid h-100">
            <Outlet />
            <FloatButton
              onClick={toggleContraste}
              style={{
                left: 20,
                width: "60px", // Ancho del botón
                height: "60px", // Alto del botón
                fontSize: "30px", // Tamaño de fuente del icono
                zIndex: 9999,
              }}
              icon={<EyeOutlined />}
            />
            <FloatButton.BackTop />
          </main>
          <LogoFooter></LogoFooter>
          <Footer></Footer>
        </HelmetProvider>
      </div>
    </>
  );
};
export default LayoutPublic;
