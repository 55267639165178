import * as React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Dropdown } from "antd";
import "./ResponsiveAppBar.css";
import { Button } from "react-bootstrap";
import { ES, US } from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";
import { useContraste } from "../../contexts/ContrasteContext";

function ResponsiveAppBar() {
  const [t, i18n] = useTranslation("global");
  const { contrasteActivado } = useContraste();

  const menuItems = [
    {
      key: "1",
      label: <Link to="/mina-el-chiflon">{t("nav.chiflon-mine")}</Link>,
    },
    {
      key: "2",
      label: (
        <Link to="/parque-isidora-cousino">
          {t("nav.isidora-cousino-park")}
        </Link>
      ),
    },
    {
      key: "3",
      label: <Link to="/iglesia-san-matias">{t("nav.san-matias-church")}</Link>,
    },
    {
      key: "4",
      label: <Link to="/fuerte-colcura">{t("nav.colcura-fort")}</Link>,
    },
    {
      key: "5",
      label: <Link to="/gota-de-leche">{t("nav.gota-de-leche")}</Link>,
    },
    {
      key: "6",
      type: "divider",
    },
    {
      key: "7",
      label: (
        <Link to="https://www.monumentos.gob.cl/monumentos/monumentos-monumentos">
          {t("nav.other-heritage")}
        </Link>
      ),
    },
  ];

  return (
    <Navbar className={contrasteActivado ? "alto-contraste color-menu" : "color-menu"} expand="lg" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img
              alt=""
              src="/logo-ceia.Webp"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
          </Link>{" "}
          CEIA SAN LUIS DE POTOSÍ
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Link to="/">
              <div className="nav-link dropdown-toggle-custom"> {t("nav.home")} </div>
              </Link>
            <Dropdown
              menu={{ items: menuItems }}
              trigger={["click"]}
              overlayClassName={contrasteActivado ? "custom-dropdown alto-contraste" : "custom-dropdown"}
            >
              <div className="nav-link dropdown-toggle dropdown-toggle-custom">
                {t("nav.heritage")}
              </div>
            </Dropdown>
          </Nav>
          <Button
            size="sm"
            variant="outline-light"
            className="m-1 btn-lng"
            onClick={() => i18n.changeLanguage("es")}
          >
            ES <ES title={t("nav.spanish")} />
          </Button>
          <Button
            size="sm"
            variant="outline-light"
            className="m-1 btn-lng"
            onClick={() => i18n.changeLanguage("en")}
          >
            EN <US title={t("nav.english")} />
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default ResponsiveAppBar;
